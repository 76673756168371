import config from '../sdk-config.js';
import {createContext, useContext, useMemo} from "react";
import {
	retrieveSessionInfo,
	verifyTransaction,
	executeDirectPurchase,
	verifyPurchases,
} from "kiwi-checkout-sdk";

const ProviderContext = createContext({});

const CheckoutSDKProvider = ({ children }) => {
	const providerValue = useMemo(
		() => ({
			sessionInfo: retrieveSessionInfo(config),
			purchase: executeDirectPurchase(config),
			verify: {
				transaction: verifyTransaction(config),
				purchases: verifyPurchases(config),
			},
		}),
		[]
	)

	return <ProviderContext.Provider value={providerValue}>
		{children}
	</ProviderContext.Provider>
}

const useCheckoutProvider = () => {
	const context = useContext(ProviderContext);
	return context;
}

export { useCheckoutProvider, CheckoutSDKProvider }