import {useState} from "react";
import {useQuery} from "react-query";
import PaymentLoading from "./PaymentLoading";
import PaymentNotifier from "./PaymentNotifier";
import usePurchase from "../hooks/usePurchase";
import {useSession} from "../context/CheckoutSessionContext";

const Pay = () => {
	return <div className="text-white font-dm-sans-bold text-2xl">Pay</div>;
}

const PayButton = ({sessionInfo, successUrl, price, errorNotifier}) => {

	const genericErrorMessage = "Error, please try again later";
	const insufficientMessage = "Insufficient funds for this item";

	const [triggerPurchase, setTriggerPurchase] = useState(false);
	const { kiwiAccount: { balance } } = sessionInfo;

	const { sessionId } = useSession();
	const { transact } = usePurchase({sessionId, sessionInfo, totalPrice: price});

	const purchase = () => {
		setTriggerPurchase(true);
	}

	const sendParentMessage = () => {
		const url = (window.location !== window.parent.location) ? `${document.referrer}` : document.location.href
		window.parent.postMessage("success", url);
	}

	const successfulRedirect = () => {
		const safeUrl = `https://${successUrl}`;
		window.location.replace((successUrl.includes("https://") ? successUrl : safeUrl));
	}

	const triggerSuccessResponse = () => {
		setTimeout(() => {
			sendParentMessage();
			if (successUrl) successfulRedirect();
		}, 1000);
	}

	const { status } = useQuery('purchase', transact, { enabled: triggerPurchase });

	const symbols = {
		"loading": <PaymentLoading />,
		"success": <PaymentNotifier status={"success"} trigger={triggerSuccessResponse}/>,
		"error": <PaymentNotifier status={"error"} trigger={errorNotifier(genericErrorMessage)}/>,
		"insufficient": <PaymentNotifier status={"error"} trigger={()=>{}} skipAnimation={true}/>,
		"idle": <Pay/>,
	}

	const color = (balance < price || status === "error") ? "bg-[#EE0000]" : "bg-[#038768]";
	const hoverColor = (balance < price || status === "error") ? "hover:bg-[#cc0000] active:bg-[#cc0000]" : "hover:bg-[#016e54] active:bg-[#016e54]";
	const style = `transition w-full h-12 px-10 ${color} ${hoverColor} rounded-lg ease-in-out duration-300 drop-shadow-sm`;

	return <div className="w-full px-10">
		<button
			className={style}
			onClick={(balance < price) ? errorNotifier(insufficientMessage) : purchase}
		>
			<div className="w-full h-full flex justify-center items-center">
				{symbols[(balance < price) ? "insufficient" : status]}
			</div>
		</button>
	</div>
}

export default PayButton;