const abbreviations = {
	"hour": "hr",
	"day": "day",
	"week": "wk",
	"month": "mo",
	"year": "yr",
}

const abbreviateTimePeriod = (timePeriod) => {
	return abbreviations[timePeriod];
}

export default abbreviateTimePeriod;