import {useState} from "react";
import {CircularProgress} from "@mui/material";
import {Done, Close} from "@mui/icons-material";

function PaymentNotifier ({trigger, status, skipAnimation}) {

	const [progressStyle, setProgressStyle] = useState('');
	const [progressValue, setProgressValue] = useState(0);
	const [switchIcon, setSwitchIcon] = useState(skipAnimation ? true : false);

	function adjustStyle () {
		setProgressStyle('animate-fade-out opacity-0');
		setTimeout(() => {
			setSwitchIcon(true)
			trigger();
		}, 300);
	}

	function progressBar () {
		setTimeout(() => {
			setProgressValue(100);
			setTimeout(adjustStyle, 500)
		}, 100);
	}

	function Icon (status) {
		const style = {
			height: "1.8em",
			width: "1.8em",
			color: "white",
			position: "relative",
		}
		return status === "success" ?
			<Done style={{...style, bottom: "0.1em"}}/> :
			<Close style={{...style}}/>;
	}

	progressBar();

	return <div>
		{
			(switchIcon) ?
				<div className="animate-scale-up">
					{Icon(status)}
				</div> :
				<div className={progressStyle}>
					<CircularProgress
						size="2em"
						variant="determinate"
						value={progressValue}
						style={{
							color: "white",
							position: "relative",
							top: "0.1em"
						}}
					/>
				</div>
		}
	</div>
}

export default PaymentNotifier;