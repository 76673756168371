import formatName from "../util/formatName";

const PurchaseTarget = ({purchaserName, receiverName}) => {
	const formattedReceiverName = (receiverName) ? formatName(receiverName) : '';
	return <div className="mx-10">
		<div className="flex">
			<div className="w-full font-dm-sans text-sm text-[#9191A1]">Transactor</div>
			<div className="w-full text-right font-dm-sans text-base">{purchaserName}</div>
		</div>
		{(receiverName) &&
			<div className="flex">
				<div className="w-full font-dm-sans text-sm text-[#9191A1]">Receiver</div>
				<div className="w-full text-right font-dm-sans text-base">{formattedReceiverName}</div>
			</div>
		}
	</div>

}

export default PurchaseTarget;