import {CircularProgress} from "@mui/material";

const PaymentLoading = () => {
	return <div role="status">
		<CircularProgress
			size="2em"
			style={{
				color: "white",
				position: "relative",
				top: "0.1em"
			}}
		/>
	</div>
}

export default PaymentLoading;