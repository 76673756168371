const BackButton = ({failureUrl}) => {

	// const sendCancelMessage = () => {
	// 	const url = (window.location !== window.parent.location) ? `${document.referrer}` : document.location.href
	// 	window.parent.postMessage("cancel", url);
	// }

	const redirectBack = () => {
		const safeUrl = `https://${failureUrl}`;
		window.location.replace((failureUrl.includes("https://") ? failureUrl : safeUrl));
	}

	// const triggerCancelResponse = () => {
	// 	sendCancelMessage();
	// 	if(failureUrl) redirectBack();
	// }

	// return <div className="flex w-[20px]" onClick={redirectBack}>
	// 	<div className="mr-2 relative top-1">
	// 		<svg
	// 			xmlns="http://www.w3.org/2000/svg"
	// 			width="16" height="16"
	// 			fill="currentColor"
	// 			className="bi bi-backspace-fill relative w-[1.25em] h-[1.25em] top-[0.025em]"
	// 			viewBox="0 0 16 16">
	// 			<path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1
	// 			0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829
	// 			5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39
	// 			8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
	// 			8 5.829 5.854z"/>
	// 		</svg>
	// 	</div>
	// 	<div className="font-dm-sans-medium text-xl">Back</div>
	// </div>

	return <svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="w-[1.5em] h-[1.5em]"
		onClick={redirectBack}
	>
		<path d="M18.875 6.50923L13.3842 12L18.875 17.4908L17.4908 18.875L12 13.3842L6.50923 18.875L5.125 17.4908L10.6158 12L5.125 6.50923L6.50923 5.125L12 10.6158L17.4908 5.125L18.875 6.50923Z" fill="#101010"/>
	</svg>
}

export default BackButton;