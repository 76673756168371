import {Route, Routes} from "react-router-dom";
import SessionNotFound from "../components/SessionNotFound";
import Checkout from "../routes/Checkout";


const routes = {
	'': {
		key: 1,
		path: '/',
		element: <SessionNotFound />
	},
	checkout: {
		key: 2,
		path: '/checkout',
		element: <Checkout />
	}
}

const RouteController = () => {
	return <Routes>
		{ Object.values(routes).map(route => <Route key={route.key} path={route.path} element={route.element} />) }
	</Routes>
}

export default RouteController;