const Calculator = ({totalPrice, balance}) => {
	return <div className="mx-10">
		<div className="flex">
			<div className="w-full font-dm-sans text-sm text-[#9191A1]">Wallet Balance</div>
			<div className="w-full text-right font-dm-sans text-base">${balance}</div>
		</div>
		<div className="flex">
			<div className="w-full font-dm-sans text-sm text-[#9191A1]">Total Price</div>
			<div className="w-full text-right font-dm-sans text-base">${totalPrice}</div>
		</div>
	</div>
}

export default Calculator;