import {useCheckoutProvider} from "../context/CheckoutSDKProvider";

function usePurchase ({sessionId, sessionInfo, totalPrice}) {

	const provider = useCheckoutProvider();

	function delay () {
		return new Promise(resolve => setTimeout(resolve, 100));
	}

	function mashCart (cart) {
		return cart.reduce((previousValue, currentValue) => {
			previousValue[currentValue.productId] = currentValue.quantity;
			return previousValue;
		}, {});
	}

	function buildPurchaseIds ({cart, purchaserId, receiverId}) {
		if(receiverId) return cart.map(item => `PURCHASE^${item.productId}^${receiverId}`);
		return cart.map(item => `PURCHASE^${item.productId}^${purchaserId}`);
	}

	async function transact() {

		const { agencyId, purchaserId, purchaser, receiverId, receiver, products, cart } = sessionInfo;

		const _cart = mashCart(cart);

		const params = {
			sessionId,
			agencyId,
			purchaserId,
			purchaser,
			receiverId,
			receiver,
			products,
			cart: _cart,
			totalPrice
		};


		let purchaseVerification;
		let transactionVerification;
		const { transactionId } = await provider.purchase(params);

		while(true){
			transactionVerification = await provider.verify.transaction({ transactionId });
			if(transactionVerification.found) break;
			await delay();
		}

		if(!transactionVerification.approved) throw new Error("Transaction failed");

		console.log("Verification Passed");

		const purchaseIds = buildPurchaseIds({cart, purchaserId, receiverId});

		while(true){
			purchaseVerification = await provider.verify.purchases({purchaserId, purchaseIds});
			if(purchaseVerification.missing.length === 0) break;
			await delay();
		}

		console.log("Purchase Located");

		return purchaseVerification;

	}

	return { transact };

}

export default usePurchase;