import {CheckoutSessionContextProvider} from "./context/CheckoutSessionContext";
import QueryClientContext from "./context/QueryClientContext";
import RouteController from "./context/RouteProvider";
import {HeightContextProvider} from "./context/HeightContext";

function App() {

	return <QueryClientContext>
		<CheckoutSessionContextProvider>
			<HeightContextProvider>
				<RouteController />
			</HeightContextProvider>
		</CheckoutSessionContextProvider>
	</QueryClientContext>
}

export default App;
