import {createContext, useContext, useState} from "react";

const HeightContext = createContext({})

const HeightContextProvider = ({ children }) => {

	const [height, setHeight] = useState('32');
	const [btnMsg, setBtnMsg] = useState('See More');
	const [increaseHeight, setIncreaseHeight] = useState(false);

	return <HeightContext.Provider value={{
		height: {height, setHeight},
		btnMsg: {btnMsg, setBtnMsg},
		increaseHeight: {increaseHeight, setIncreaseHeight},
	}}>
		{children}
	</HeightContext.Provider>;

}

const useHeight = () => {
	const context = useContext(HeightContext);
	return context
}

export {useHeight, HeightContextProvider};