import {useSession} from "../context/CheckoutSessionContext";
import formatName from "../util/formatName";
import Loading from "../components/Loading";
import SessionNotFound from "../components/SessionNotFound";
import CheckoutBody from "../components/CheckoutBody";
import TopBar from "../components/TopBar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Checkout = () => {

	const { data: sessionInfo, status } = useSession();

	const Page = () => {

		const { name } = sessionInfo;
		const formattedPurchaserName = formatName(name);

		const errorNotifier = (message) => () => toast.error(message, {toastId: "error"});

		return <div className="min-w-[260px] bg-[#EAEAEA] fixed">
			<TopBar />
			<div className="overflow-y-scroll h-screen">
				<div className="my-9">
					<CheckoutBody
						sessionInfo={sessionInfo}
						errorNotifier={errorNotifier}
						purchaserName={formattedPurchaserName}
					/>
				</div>
			</div>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</div>
	}

	if (status === "loading") {
		return <Loading />;
	}
	else if (sessionInfo.response?.status === 404) {
		return <SessionNotFound />;
	}

	return <Page />;

}

export default Checkout;