import {useQuery} from "react-query";
import {useSearchParams} from "react-router-dom";
import {useCheckoutProvider} from "./CheckoutSDKProvider";
import {createContext, useContext, useEffect, useState} from "react";

const CheckoutSessionContext = createContext({})

const CheckoutSessionContextProvider = ({ children }) => {

	const provider = useCheckoutProvider();
	const [searchParams] = useSearchParams();
	const [sessionId, setSessionId] = useState('');

	const querySession = async () => {
		const querySessionId = searchParams.get('session_id').toUpperCase();
		setSessionId(querySessionId);
		console.log(querySessionId);
		return provider.sessionInfo(querySessionId).then(res => {
			console.log(res);
			if(res.error) throw new res.error();
			return res;
		});
	}

	const {data, status} = useQuery("sessionInfo", querySession, );

	useEffect(() => {
		console.log(data);
		console.log(status);
	}, [data, status])

	return <CheckoutSessionContext.Provider value={{data, status, sessionId}}>
		{children}
	</CheckoutSessionContext.Provider>;

}

const useSession = () => {
	const context = useContext(CheckoutSessionContext);
	return context;
}

export {useSession, CheckoutSessionContextProvider};