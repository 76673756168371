import Icons from "../assets/Icons";

const TopBar = () => {
	return <div className="flex justify-between flex-wrap bg-white py-3 drop-shadow-sm w-screen px-6 text-xl">
		<div className="flex items-center">
			<div className="mr-3">
				<Icons.Logo />
			</div>
			<span className="text-black font-dm-sans-bold">Check Out</span>
		</div>
		<div className="flex">
			<Icons.FillShoppingCart color="#038768" />
		</div>
	</div>;
}

export default TopBar;