import abbreviateTimePeriod from "../util/abbreviateTimePeriod";

const formatString = (str, length) => {
	if(str.length <= length) return str;
	return `${str.slice(0, length)}...`;
}

const CartItemBuilder = (cart) => (product) => {
	const { SK, data: { name, description, kiwiPrice, period } } = product;

	// const { productId, quantity } = cart.filter(item => item.productId === SK).shift();
	const { quantity } = cart.filter(item => item.productId === SK).shift();

	const _name = formatString(name, 20);
	const _description = formatString(description, 50);
	const _price = (period === "ONETIME") ? kiwiPrice :
		`${kiwiPrice}/${abbreviateTimePeriod(period.toLowerCase())}`

	return <div className="flex mb-4" key={SK}>
		<div className="w-[65%]">
			<div className="font-dm-sans-bold text-base truncate w-[90%]">{quantity} x {_name}</div>
			<div className="font-dm-sans text-sm text-[#9191A1] truncate">{_description}</div>
		</div>
		<div className="w-[35%] text-right font-dm-sans-medium text-base">${_price}</div>
	</div>
}

const Cart = ({products, cart}) => {
	const CartItem = CartItemBuilder(cart);
	return <div>
		<div >
			{products.map(CartItem)}
		</div>
	</div>
}

export default Cart;