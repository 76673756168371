import React from "react";

const Icons = {
	Logo: () => {
		return <svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none" xmlns="http://www.w3.org/2000/svg"
			className="w-[1em] h-[1em] relative"
		>
			<path d="M13.6612 2.34812C12.153 0.840956 10.1202 0 8 0H0V7.99454C0 10.1133 0.852459 12.1447 2.34973
			13.641C3.84699 15.1481 5.87978 15.9891 8 16H16V7.99454C16 5.87577 15.1585 3.84437 13.6612
			2.34812ZM6.44809 11.7406C5.95628 11.5331 5.5082 11.2382 5.13661 10.8669C4.76503 10.4956 4.45902 10.0478
			4.2623 9.55631C4.05465 9.06485 3.95628 8.54061 3.95628 8.00546C3.94536 5.76655 5.75956 3.92082 8
			3.92082C8.53552 3.92082 9.06011 4.03003 9.55191 4.22662C10.0437 4.43413 10.4918 4.72901 10.8634 5.10034C11.235
			5.47167 11.541 5.91945 11.7377 6.41092C11.9454 6.90239 12.0437 7.42662 12.0437
			7.96177V11.9918V12.0137V12.0464H8C7.46448 12.0464 6.93989 11.9372 6.44809 11.7406Z" fill="#038768"/>
		</svg>
	},
	ShoppingCart: () => {
		return <svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="currentColor"
			className="bi bi-cart3 w-[1em] h-[1em] relative top-[0.25em]"
			viewBox="0 0 16 16"
		>
			<path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0
				1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0
				0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0
				1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
		</svg>;
	},
	FillShoppingCart: ({color}) => {
		return <svg
			xmlns="http://www.w3.org/2000/svg"
			width="16" height="16"
			fill={color}
			className="bi bi-cart-fill w-[1.1em] h-[1.1em] relative"
			viewBox="0 0 16 16">
			<path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0
			0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0
			0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0
			1 0-2z"/>
		</svg>
	}
}

export default Icons;