import Cart from "./Cart";
import Calculator from "./Calculator";
import PurchaseTarget from "./PurchaseTarget";
import PayButton from "./PayButton";
import { useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { useHeight } from "../context/HeightContext";
import BackButton from "./BackButton";

const CheckoutBody = ({ sessionInfo, purchaserName, errorNotifier }) => {

	const {
		height: {height, setHeight},
		btnMsg: {btnMsg, setBtnMsg},
		increaseHeight: {increaseHeight, setIncreaseHeight}
	} = useHeight();

	const { products, kiwiAccount: { balance }, successUrl, receiverName, cart, failureUrl } = sessionInfo;
	const price = products.map(item => item.data.kiwiPrice).reduce((total, currentValue) => { return total + currentValue }, 0);

	useEffect(() => {
		if(increaseHeight) {
			setBtnMsg('See Less')
			setHeight('auto');
		}
		else{
			setBtnMsg('See More')
			setHeight('40')
		}
	}, [increaseHeight, setBtnMsg, setHeight])


	const toggleHeight = () => {
		setIncreaseHeight(height => !height);
	}

	return <div className="h-auto bg-white mx-6 min-[360px]:mx-auto rounded-2xl py-6 max-w-[360px] drop-shadow-sm">
		<div className="mb-4 flex text-xl">
			<div className="w-full ml-7">
				<div className="font-dm-sans-bold">Cart</div>
			</div>
			<div className="w-full float-left">
				<div className="float-right mr-6">
					<BackButton failureUrl={failureUrl}/>
				</div>
			</div>
		</div>
		<div className="mx-10">
			<AnimateHeight height={(height !== 'auto') ? parseInt(height) : height} duration={(height !== 'auto') ? 0 : 500}>
				<Cart products={products} cart={cart}/>
			</AnimateHeight>
		</div>
		<hr className="w-auto mx-5 mt-6 mb-2" />
		{(cart.length > 1) && <div>
			<div className="relative bottom-5">
				<button
					className="border rounded-3xl px-4 py-1 text-center bg-white text-xs w-40 relative left-[29%]"
					onClick={toggleHeight}
				>
					{btnMsg}
				</button>
			</div>
		</div>}
		<div className="relative">
			<div className="font-dm-sans-bold text-xl mb-2 ml-8">Summary</div>
			<Calculator balance={balance} totalPrice={price}/>
			<hr className="w-auto mx-4 my-4"/>
			<PurchaseTarget purchaserName={purchaserName} receiverName={receiverName}/>
			<hr className="w-auto mx-4 my-4"/>
			<PayButton sessionInfo={sessionInfo} successUrl={successUrl} price={price} errorNotifier={errorNotifier}/>
		</div>
		{(balance < price) && <div className="font-dm-sans text-[16px, 22px] text-[#EE0000] text-center mt-4">Insufficient funds, please go back.</div>}
	</div>
}

export default CheckoutBody;